/**
 * После загрузки страницы выполняется проверка браузера и в случае если он считается мобильным - отображается заглушка.
 * Общее решение для всех страниц авторизации.
 */

import MobileDetect from "mobile-detect";

document.addEventListener("DOMContentLoaded", () => {
  let md = new MobileDetect(window.navigator.userAgent);
  if (md.mobile()) {
    document.querySelector("div.screen-coming-soon").classList.add("screen-coming-soon_active");
  }
});
